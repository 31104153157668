export function setItemWithExpiry(key, value, ttl) {
    const now = new Date()
    const item = {
        value: value,
        expiry: now.getTime() + ttl,
    }
    localStorage.setItem(key, JSON.stringify(item))
}

export function getItemWithExpiry(key) {
    const itemStr = localStorage.getItem(key)
    if (!itemStr) {
        return null
    }
    const item = JSON.parse(itemStr)
    const now = new Date()
    if (now.getTime() > item.expiry) {
        localStorage.removeItem(key)
        return null
    }
    return item.value
}

export function removeItemWithExpiry(key) {
    localStorage.removeItem(key)
}

// import { setItemWithExpiry, getItemWithExpiry } from '@/utils/storage.js'
//
// // 使用示例
// setItemWithExpiry('myKey', 'myValue', 1000 * 60 * 60) // 存储1小时
// const myValue = getItemWithExpiry('myKey') // 获取数据，如果超过1小时则返回null