import { createStore } from "vuex";

export default createStore({
  state: {
    sectionMode: "",
    homeSections: [],
    figuresInCart: 0,
  },
  mutations: {
    changeSection(state, payload) {
      state.sectionMode = payload;
    },
    addSection(state, payload) {
      state.homeSections.push(payload);
    },
    clearSections(state) {
      state.homeSections = [];
    },
    setFiguresInCart(state, count) {
      state.figuresInCart = count;
    },
    addFigureToCart(state) {
      state.figuresInCart++;
    },
  },
  actions: {
    changeSection(context, payload) {
      context.commit("changeSection", payload);
    }
  },
  getters: {
    sectionMode(state) {
      return state.sectionMode;
    },
    homeSections(state) {
      return state.homeSections;
    },
    figuresInCart(state) {
      return state.figuresInCart;
    },
  },
  modules: {},
});
