<template>
<div>success</div>
</template>

<script>
import request from "@/utils/request";

export default {
  name: "Success",
  data(){
    return{
      queryParam:{},
      queryParam2:{}
    }
  },
  mounted() {
    this.queryParam2=JSON.parse(localStorage.getItem('car'));
    this.getData()
    this.addorder()
  },
  methods:{
    getData(){
      if(document.URL.indexOf("?")<0) return 0;//获取当前url地址,如果没参数.直接返回
      var str=document.URL.split("?")[1];
      var obj={};
      var arr;
      //当只有一个参数传过来时
      if(str.indexOf("&")<0){
        if(str.indexOf("=")<0) return;
        arr=str.split("=");
        this.queryParam[arr[0]]=arr[1]
      }
      //当有多个参数时
      arr=str.split("&");
      var arr1;
      var i=0;
      for(i=0;i<arr.length;i++){
        arr1=arr[i].split("=");
        this.queryParam[arr1[0]]=arr1[1]
      }
    },
    addorder(){
      request.post("/success", this.queryParam2,{params:this.queryParam}).then(res=>{
        if(res.data.status==200){
          this.$router.push({path:"/"})
          localStorage.removeItem("car")
          this.$message.success("支付成功")
        }
        else{
          this.$router.push({path:"/"})
          this.$message.error("支付失败")
        }

      }).catch(res=>{
        this.$message.error("超时,自行查看订单")
        this.$router.push({path:"/"})
      })
    },
  }
}
</script>

<style scoped>

</style>