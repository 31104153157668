<template>
  <div class="logo">
    <a href="/"><img class="icon-mooze" src="../assets/icon/mooze.png" alt="mooze"/></a>
  </div>
</template>

<script>
export default {
  name: "LogoTesla",
  methods: {}
};
</script>

<style scoped>
.icon-mooze{
  display: block;
  width: auto;
  height: .2604rem;
  min-height: 30px;
  cursor: pointer;
}

/* @media screen and (max-width: 768px) {
  .icon-mooze {
    width: auto;
    height: 25px;
  }
} */
</style>
