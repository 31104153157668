<template>
  <div>
    <div v-if="isImage" :id="idSection" class="section-container" :style="{ backgroundImage: `url(${bgImage})` }"
      @mouseover="changeSection">
      <transition name="fade" mode="out-in">
        <div class="data-container" v-if="showDiv">
          <div class="text-container">
            <label class="title">{{ title }}</label>
            <p class="paragraph">{{ paragraph }}</p>
          </div>
          <div class="button-container">
            <button class="button2" @click="toDevTo()">
              {{ $t('MainPage_LearnMore') }}
            </button>
          </div>
        </div>
      </transition>
    </div>


    <div class="video-background" v-else :id="idSection" @mouseover="changeSection">
      <video ref="video" autoplay loop muted disablepictureinpicture disableRemotePlayback playsinline>
        <source :src="bgImage" type="video/mp4">
        Your browser does not support the video tag.
      </video>
      <div class="overlay">
        <transition name="fade" mode="out-in">
          <div class="data-container" v-if="showDiv">
            <div class="text-container">
              <label class="title">{{ title }}</label>
              <p class="paragraph">{{ paragraph }}</p>
            </div>
            <div class="button-container">
              <button class="button2" @click="toDevTo(title)">{{ $t('MainPage_LearnMore') }}
              </button>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import request from "@/utils/request";

export default {
  name: "Section",
  data() {
    return {
      isImage: true,
      isScrolling: false
    }
  },
  props: ["key", "idSection", "title", "paragraph", "bgImage", "buttons", "showDiv"],
  computed: {
    sectionMode() {
      return this.$store.getters["sectionMode"];
    },
  },
  methods: {
    changeSection() {
      this.$store.dispatch("changeSection", this.title);
    },
    toDevTo() {
      // 根据 idSection 获取产品详情，/moozemall/figure
      request.get(`/moozemall/figure/${this.idSection}`).then(res => {
        const bunchId = res.data.data.bunchId;
        this.$router.push({
          name: 'Detail',
          params: { bunchId: bunchId }
        });
      });
    }
  },
  mounted() {
    const fileExtension = this.bgImage.split('.').pop();
    const extensions = fileExtension.toLowerCase();
    if (extensions === 'mp4') {
      // 如果后缀为 mp4，则设置 isImage 为 false
      this.isImage = false;
    } else if (extensions === 'png' || extensions === 'jpg' || extensions === 'jpeg') {
      // 如果后缀为 png，则保持 isImage 为 true
      this.isImage = true;
    }
  }
};
</script>

<style scoped>
.section-container {
  display: flex;
  justify-content: center;
  background-size: auto 100%;
  background-repeat: no-repeat;
  object-fit: cover;
  padding: 50px 0px 0px 0px;
  height: calc(100vh - 50px);
  position: relative;
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  background-position: 25%;
}

.data-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  padding-bottom: 188px;
  box-sizing: border-box;
}

.text-container {
  display: flex;
  flex-direction: column;
  height: 40%;
  width: 100%;
  justify-content: end;
  padding-left: 1.0417rem;
  padding-bottom: .2604rem;
  box-sizing: border-box;
}

.title {
  max-width: 400px;
  margin-bottom: .0521rem;
  color: #ffffff;
  font-size: .3333rem;
  font-weight: 700;
}

.paragraph {
  max-width: 400px;
  font-size: .125rem;
  line-height: 1.8;
  font-weight: 300;
  word-wrap: break-word;
  white-space: pre-wrap;
  color: rgba(255, 255, 255, 1);
}

.button-container {
  display: flex;
  flex-direction: row;
  padding-left: 1.0417rem;
}

.button2 {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 200px;
  height: 48px;
  padding: .0417rem .0833rem;
  border-radius: 3px;
  border: none;
  cursor: pointer;
  background-color: rgba(34, 34, 34, 0.5);;
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  border: 1px solid #FFFFFF;
  box-sizing: border-box;
}
.button2:hover {
  opacity: .8;
}

@supports (font-size: max(0px)) {
  .title {
    font-size: max(.3333rem, 48px)
  }
  .paragraph {
    font-size: max(.125rem, 18px);
  }
  .button2 {
    font-size: max(.0833rem, 13px);
  }
}


@media screen and (max-width: 768px) {
  .section-container {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  .data-container {
    height: 100%;
  }

  .text-container {
    gap: 10px;
    justify-content: end;
    padding-bottom: 40px;
  }

  .button-container {
    height: 32px;
  }

  .title {
    max-width: 80%;
    font-size: 32px;
  }

  .paragraph {
    font-size: 12px;
    word-wrap: break-word;
    max-width: 80%;
  }

  .button2 {
    width: 120px;
    height: 32px;
    font-size: 12px;
  }
}
</style>

<style scoped>
.video-background {
  position: relative;
  width: 100vw;
  height: calc(100vh - 4px);
}

.video-background video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.overlay {
  position: absolute;
  top: 3%;
  left: 0;
  width: 100%;
  height: 93%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
