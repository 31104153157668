<template>
  <div class="main-container">
    <div class="fitter"></div>
    <div class="title_name">{{ $t('TopMenu_Cart') }}</div>
    <el-table ref="multipleTableRef" :data="tableData" class="table" stripe style="width: 90%;margin: auto"
      @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="30" />
      <el-table-column label="" width="120">
        <template #default="scope"><el-image style="width: 120px; height: 120px" class="pic" :src="scope.row.pic"
            fit="contain" /></template>
      </el-table-column>
      <el-table-column property="totalName" label="" width=auto />

      <el-table-column label="" width="150">
        <template #default="scope">{{ scope.row.priceStr }}</template>
      </el-table-column>
      <el-table-column label="" width="100">
        <template #default="scope">
          <button class="btn1" @click="delecar(scope.row.carId)"><el-icon size="20">
              <DeleteFilled />
            </el-icon></button></template>
      </el-table-column>

    </el-table>
    <el-button v-if="multipleSelection.length == 0" class="disabled" disabled>{{ $t('Common_Continue') }}</el-button>
    <el-button v-else class="btn" @click="gotoPurchase">{{ $t('Common_Continue') }}</el-button>

    <div class="fitter"></div>
  </div>
  <Footer />
</template>

<script>
import Footer from "@/components/Footer";
import request from "@/utils/request";
import { getItemWithExpiry } from "@/utils/storage";
import { ElMessageBox } from "element-plus";

export default {
  name: "Cart",
  components: { Footer },
  data() {
    return {
      queryParam: {
        userId: 1,
        languageId: this.$i18n.locale
      },
      tableData: [],
      multipleSelection: []
    }
  },
  mounted() {
    const userId = getItemWithExpiry('userId');
    if (userId == null)
      this.$router.push('/login')
    this.queryParam.userId = userId
    this.getcar()
  },

  methods: {
    getcar() {
      request.get("moozemall/car/list", { params: this.queryParam }).then(res => {

        this.tableData = res.data.data
      }).catch(err => {
        console.error(err)
      })
    },
    delecar(id) {
      ElMessageBox.confirm(`确定删除这个商品吗 ?`)
        .then(() => {
          request.delete("moozemall/car/" + id).then(res => {
            console.log(id)
            this.$message.success('删除成功')
            window.location.reload()
            this.getcar();
          })
        })
        .catch(() => {
          // catch error
        });

    },
    handleSelectionChange(val, row) {
      this.multipleSelection = val;
    },
    gotoPurchase() {
      this.$router.push({ path: `/purchase` })
      localStorage.setItem('car', JSON.stringify(this.multipleSelection))
    }
  }
}
</script>

<style scoped>
.main-container {
  min-height: calc(100vh - 40px);
  overflow-x: hidden;
}

Footer {
  position: absolute;
  bottom: 0;
}

.fitter {
  width: 100%;
  display: flex;
  padding-top: 70px;
}

.title_name {
  margin: 10px;
  font-size: 40px;
  text-align: center;
}

.pic {
  width: 60px;
  height: 75px;
}

.btn1 {
  padding: 10px;
  background: none;
}

.btn1:hover {
  background: #2c3e50;
}

.disabled {
  margin: 50px 80%;
}

.btn {
  margin: 50px 80%;
}
</style>
