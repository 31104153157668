export function switchLanguage(language) {
    let fontClass;
    switch (language) {
        case '1':
            fontClass = 'font-english';
            break;
        case '2':
            fontClass = 'font-japanese';
            break;
        case '3':
            fontClass = "font-chinese";
            break;
        default:
            fontClass = "font-chinese";
    }

    // 获取需要改变字体的元素，这里假设是 body
    const body = document.querySelector('body');

    // 移除旧的字体类
    body.classList.remove('font-chinese', 'font-rajdhani', 'font-english', 'font-japanese');

    // 添加新的字体类
    body.classList.add(fontClass);

}