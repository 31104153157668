<template>
  <div class="selector">
    <div class="language-card">
      <div v-for="(locale, key) in $i18n.messages" :key="key" @click="changeLanguage(key)" class="language-option">
        {{ locale.language }}
      </div>
    </div>
  </div>
</template>

<script>
import i18n from "@/locale";

export default {
  name: "LanguageSelector",
  data() {
    return {}
  },
  methods: {
    changeLanguage(key) {
      localStorage.setItem('userLanguage', key);
      window.location.reload();
    }
  }
}
</script>

<style scoped>
.selector {
  position: absolute;
  top: 24px;
  right: -20%;
  padding: 10px 20px;
  background-color: var(--dark-gray);
  border-radius: 2px;
  box-sizing: border-box;
}

.language-option {
  text-align: left;
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: center;
  height: 32px;
  white-space: nowrap;
  cursor: pointer;
}

.language-option:hover {
  color: #DCC291;
}

@media screen and (max-width: 768px) {
  .language-selector.selector {
    top: 0;
    left: 0;
  }
  .selector {
    height: 100%;
    width: 100%;
    padding: 10px 0;
    background: var(--dark-gray);
  }

  .language-card {
    padding-top: 40px;
  }

  .language-option {
    text-decoration: none;
    padding-left: 16px;
    height: auto;
    margin-bottom: 32px;
    color: #ffffff;
    box-sizing: border-box;
    text-align: left;
    font-size: 16px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
}
</style>