<template>
  <div class="fitter"></div>
  <div class= "main-container">


  <div class="newName">
    {{ New.title }}
  </div>
  <div class="time">{{ New.time }}</div>

  <img v-if="New.pic && New.pic.toLowerCase()" class="pic" :src="baseUrl+New.pic" alt="">
  <div v-if="New.abs && New.abs.toLowerCase()" class="abs">{{ New.abs }}</div>
  <div class="abs2">{{ New.text }}</div>
  <div class="fitter"></div>
  </div>
  <Footer/>
</template>

<script>
import Footer from "@/components/Footer.vue";
import request from "@/utils/request";

export default {
  name: "Newdetail",
  components: {
    Footer
  },
  mounted() {
    this.New=JSON.parse(localStorage.getItem('New'));
    console.log(this.New)
  },
  data() {
    return {
      baseUrl: request.defaults.baseURL+'/images/',
      New: {
        id: 1,
        time: '2024-01-03',
        title: 'new1111111111111111',
        abs: 'ssssssssssssssssssssssssssssssssssssssssssssssssss',
        text: 'adssssssssssssssssssssssssssssswaaaaaascxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx',
        pic: 'https://g-search3.alicdn.com/img/bao/uploaded/i4/i2/2214325162924/O1CN015t9noa1XTF0K9qBAP_!!2214325162924.png_580x580q90.jpg_.webp'
      }
    }
  }
}
</script>

<style scoped>
.main-container{
  min-height: calc(100vh - 40px);
  overflow-x: hidden;
  width: 60%;
  margin: auto;
}
.fitter {
  width: 100%;
  display: flex;
  padding-top: 70px;
}

.newName {
  margin-bottom: 10px;
  width: 100%;
  color: #171A20;
  font-size: 40px;
  word-break: break-word;

}

.time {
  font-size: 12px;
  margin-bottom: 50px;
  color: #5C5E62;
}

.abs {
color: #393C41;
  font-size: 14px;
  width: 100%;
  margin: 30px auto;
  flex-wrap: wrap;
  word-break: break-word;
  white-space: pre-wrap;
}

.abs2 {
  color: #393C41;
  font-size: 14px;
  width: 100%;
  margin: 20px auto;
  flex-wrap: wrap;
  word-break:break-word;
  white-space: pre-wrap;
}

.pic {
  height: auto;
  width: 100%;
}
@media screen and (max-width: 768px){
  .main-container{
    width: 90%;
  }

}
</style>