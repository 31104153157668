import {createRouter, createWebHistory} from 'vue-router'
import ViewHome from "@/views/ViewHome.vue";
import Figures from "@/views/Figures.vue";
import News from "@/views/News.vue";
import Login from "@/views/Login.vue";
import NewDetail from "@/views/Newdetail.vue";
import Contact_us from "@/views/Contact_us.vue";
import Privacy from "@/views/Privacy.vue";
import Register from "@/views/Register.vue";
import Cart from "@/views/Cart.vue";
import Purchase from "@/views/Purchase.vue";
import Success from "@/views/Success.vue";
import error from "@/views/error.vue";
import request from "@/utils/request";
import { getItemWithExpiry } from "@/utils/storage";
import store from "@/store";

const routes = [
    {
        path: "/",
        name: "ViewHome",
        component: ViewHome,
    },
    {
        path: "/figures",
        name: "Figures",
        component: Figures,
    },
    {
        path: "/bag",
        name: "Cart",
        component: Cart,
    },
    {
        path: "/figuresDetail",
        name: "FiguresDetail",
        component: () => import("@/views/FiguresDetail.vue")
    },
    {
        path: "/news",
        name: "News",
        component: News,
    },
    {
        path: "/login",
        name: "Login",
        component: Login,
        meta: {requiresGuest: true},
    },
    {
        path: "/register",
        name: "Register",
        component: Register,
    },
    {
        path: "/setPassword",
        name: "SetPassword",
        component: () => import("@/views/SetPassword.vue")
    },
    {
        path: "/resetPassword",
        name: "ResetPassword",
        component: () => import("@/views/ResetPassword.vue")
    },
    {
        path: "/account",
        name: "Account",
        component: () => import("@/views/Account.vue"),
        meta: {requiresAuth: true}
    },
    {
        path: "/order",
        name: "Order",
        component: () => import("@/views/Order.vue"),
        meta: {requiresAuth: true}
    },
    {
        path: "/newDetail",
        name: "NewDetail",
        component: NewDetail,
    },
    {
        path: "/contact",
        name: "Contact_us",
        component: Contact_us,
    },
    {
        path: "/privacy",
        name: "privacy",
        component: Privacy,
    },
    {
        path: "/purchase",
        name: "purchase",
        component: Purchase,
    },
    {
        path: "/success",
        name: "success",
        component: Success,
    },{
        path: "/error",
        name: "error",
        component: error,
    },{
        path: "/details/:bunchId",
        name: 'Detail',
        component: () => import('@/views/detail/Detail.vue')
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

// 添加全局前置守卫
router.beforeEach((to, from, next) => {
    const itemWithExpiry = getItemWithExpiry('token');
    const isLoggedIn = localStorage.getItem('token');  // 检查用户是否已经登录

    if (to.matched.some(record => record.meta.requiresAuth)) {
        // 这个路由需要用户已经登录
        if (itemWithExpiry==null) {
            // 用户未登录，重定向到登录页面
            next({name: 'Login'});
        } else {
            next();
        }
    } else if (to.matched.some(record => record.meta.requiresGuest)) {
        // 这个路由需要用户未登录
        if (isLoggedIn) {
            // 用户已登录，重定向到用户信息页面
            next({name: 'Account'});
        } else {
            next();
        }
    } else {
        // 这个路由没有任何限制，直接放行
        next();
    }

});

export default router
