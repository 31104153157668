<template>

    <div class="pic">
      <img src="@/assets/privacy.avif" class="pic1" alt="">
    </div>
  <div class="main-container">
  <div class="fitter"></div>
  <div class="fitter"></div>
  <div class="newName">
    {{ $t('BottomBar_Privacy') }}
  </div>
  </div>
    <div class="main-container" >
      <div style="font-size: 30px;margin-bottom: 30px">
        {{New.title}}
      </div>
  <div class="abs2">{{ New.text }}</div>
  <div class="fitter"></div>
  </div>
  <Footer/>
</template>

<script>
import Footer from "@/components/Footer.vue";
import request from "@/utils/request";
export default {
  name:"privacy",
  components: {Footer},
  data() {
    return {
      New: {
        id: 1,
        time: '2024-01-03',
        title: '隐私和法律',
        abs: 'ssssssssssssssssssssssssssssssssssssssssssssssssss',
        text: '客户隐私声明\n' +
            '您的隐私对我们来说至关重要。我们的稳私声明旨在提升透明度，以易于阅读和浏览的形式，帮助您了解我们的隐私保护措施。请阅读以下章节，了解我们如何收集、使用、分享和保护您的信息，以为您提供流畅的车辆产品和能源产品使用体验。本声明适用于特斯拉汽车（北京）有限公司、特斯拉汽车销售服务（北京）有限公司及其在中国境内的关联公司（“我们”）为您提供产品和服务。\n' +
            '\n' +
            '隐私保护从第一天开始\n' +
            '您的 Tesla 会生成车辆、诊断、信息娱乐系统和 Autopilot 自动辅助驾驶数据。从您接受车辆交付时，隐私保护即刻开启。一般情况下我们不会将驾驶行为生成的车辆数据与您的身份信息或账户相关联。因此除您本人之外，其他人无法得知您的驾驶行为。您在车厢内的其它活动也同样受到保护。我们所收集的信息娱乐数据（例如语音命令、通过触摸屏浏览网页等）也不会与您的身份信息或账户关联，充分保证您的信息私密性和安全性。\n' +
            '\n' +
            'Tesla 车辆均配备摄像头套件，其用途是提供基础版辅助驾驶功能，以及智能召唤和自动泊车等高级功能。这套系统从设计之初即嵌入了隐私保护理念。来自摄像头套件的 Autopilot 自动辅助驾驶数据默认会在车辆本地进行处理，以识别车道线、路牌和交通信号灯位置等。在符合当地法律的条件下，只有当您开启数据分享后，摄像头记录的内容才会被传输和分享，并用于车队学习。您可以随时通过车辆的触摸屏开启或关闭数据分享功能。即使您选择开启数据分享，摄像头记录的内容也会以匿名的方式传输，而不会与您或您的车辆相关联，除非发生了安全事件（例如碰撞、气囊弹出或自动紧急制动事件）。\n' +
            '\n' +
            '此外，能源产品如 Powerwall 和 Solar Roof 太阳能屋顶等，在设计时也同样注重隐私保护。我们的目标是收集尽可能少量的个人数据，范围仅限应用程序正常使用、为您提供服务、以及改进您的能源产品所必要的信息。我们还承诺仅在需要操作或维护您的产品时分享您的个人数据，否则我们将会先征得您的同意。\n' +
            '\n' +
            '我们可能收集的信息\n' +
            '\n' +
            '我们如何使用您的信息\n' +
            '\n' +
            '分享您的信息\n' +
            '\n' +
            '选择与透明性\n' +
            '\n' +
            '隐私相关问题\n' +
            '\n' +
            '\n' +
            '我们可能收集的信息\n' +
            '我们可能会收集以下三类信息，这三类信息可能与您本人或您对我们产品和服务的使用情况有关：\n' +
            '\n' +
            '来自您或有关您的信息\n' +
            '来自或关于您 Tesla 车辆的信息\n' +
            '来自或关于您 Tesla 能源产品的信息\n' +
            '\n' +
            '根据您申请、拥有或使用的 Tesla 产品和服务，并非所有上述类别的信息都适用于您。\n' +
            '\n' +
            ' 显示详情\n' +
            '\n' +
            '我们如何使用您的信息\n' +
            '我们可能将收集到的信息用于：\n' +
            '\n' +
            '与您沟通\n' +
            '实现我们的产品和服务\n' +
            '改善及强化开发我们产品和服务\n' +
            '\n' +
            '这包括与您联系以告知您重要的安全相关信息，在发生涉及您车辆的事故时通知紧急响应人员，提供为您量身定制的产品和优惠，或响应您的询问并满足您对产品信息、Tesla 更新或其他活动的请求。\n' +
            '\n' +
            ' 显示详情\n' +
            '\n' +
            '分享您的信息\n' +
            '我们可能会与以下人员分享信息：\n' +
            '\n' +
            '我们的服务提供商、业务合作伙伴和关联方\n' +
            '您授权的第三方\n' +
            '法律规定的其他第三方\n' +
            '\n' +
            '我们限制我们分享您个人数据的方式和对象。我们何时可以分享您的信息的例子包括：处理付款、订单履行、产品安装、客户服务、市场营销、金融服务、售后服务或维修以及其他类似服务。\n' +
            '\n' +
            ' 显示详情\n' +
            '\n' +
            '选择与透明性\n' +
            '您可以随时访问和控制我们收集、使用和分享的数据，包括以下方式：设备权限管理、手机应用程序的设置选项、联系方式偏好设置、您的 Tesla 账户和车载数据分享控制。对于您的数据，您可以自由掌控。\n' +
            '\n' +
            ' 显示详情\n' +
            '隐私相关问题\n' +
            '如果您有关于隐私的问题或意见，或要退订某些服务，或提交隐私数据申请，请联系我们。\n' +
            '\n' +
            '更新于 2023 年 8 月',
        pic: 'https://g-search3.alicdn.com/img/bao/uploaded/i4/i2/2214325162924/O1CN015t9noa1XTF0K9qBAP_!!2214325162924.png_580x580q90.jpg_.webp'
      }
    }
  },
  mounted() {
      this.gettext()
  },
  methods: {
    gettext(){
      request.get('moozemall/text/1/'+this.$i18n.locale).then(res=>
      {
        if(res.data.data!=null)
        this.New=res.data.data
      })
    }
  }

}
</script>

<style scoped>
.main-container{
  min-height: 700px;
  overflow-x: hidden;
  max-width: 600px;
  width: 60%;
  margin-left: 15%;
}

.fitter {
  width: 100%;
  display: flex;
  padding-top: 70px;
}

.newName {
  margin: 10px;
  font-size: 40px;
  margin: 30px auto;

}
.pic{
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 650px;
  z-index: -1;
}
.pic1{
  width: 1620px;
  height: 650px;
  position: absolute;
  left: 50%;
  margin-left: -810px;
  contain: size;

}
.abs2 {
  font-size: 14px;
  margin: auto;
  flex-wrap: wrap;
  word-break: break-word;
  white-space: pre-wrap;
}

Footer{
  position: absolute;
  bottom: 0;
}
@media screen and (max-width: 768px){

  .main-container{
    width:90%;
    min-height:400px;
    margin:auto;
  }
  .pic{
    position: absolute;
    overflow: hidden;
    width: 100%;
    height: 350px;
    z-index: -1;
  }
  .pic1{
    width: 800px;
    height: 350px;
    position: absolute;
    left: 50%;
    margin-left: -400px;
    contain: size;

  }
  .fitter{
    display: none;
  }
  .newName{
    display: none;
  }
}
</style>
