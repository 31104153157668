<template>
  <div class="pic1">
    <img src="@/assets/new.avif" class="pic2" alt="">
  </div>
  <div class="main-container">
    <div class="fitter"></div>
    <div class="fitter"></div>
    <div class="fitter"></div>
    <div class="title_name">
      {{ $t('TopMenu_News') }}
    </div>
  </div>
  <div class="main-container">

  <div class="box" v-for="i in news" :key="i.id"  @mouseenter="enterbox(i.id)" @mouseleave="leavebox(i.id)">

    <div class="new" v-if="i.pic && i.pic.toLowerCase()">
    <div >
      {{ i.title}}
    </div>
    <div class="time">{{i.time}}</div></div>
<div class="pic" v-if="i.pic && i.pic.toLowerCase()">
  <img style="height: 100%;width: 100%;object-fit: contain" :src="baseUrl+i.pic" alt='' />
</div>

    <div class="content" v-if="i.pic && i.pic.toLowerCase()">
      <div  v-if="i.abs && i.abs.toLowerCase()">
      {{i.abs}}
      </div>
      <div  v-else>
        {{i.text}}
      </div>
  </div>
    <div class="new" v-else>
      <div>
        {{ i.title }}

      </div>
      <div class="time" >{{ i.time }}</div>
      <div class="content1" v-if="i.abs && i.abs.toLowerCase()">
        {{i.abs}}
      </div>
      <div class="content1" v-else>
        {{i.text}}
      </div>
    </div>
    <el-link class="line3" :underline="false"  style="" @click="goToDetail(i)">{{ $t('MainPage_LearnMore') }}</el-link>

  </div>
<div   class="ot">
      <el-pagination
          small
          @current-change="handleCurrentChange"
          :current-page="queryParam.currentPage"

          :page-size="queryParam.pageSize"
          layout="total, prev, pager, next, jumper"
          :total="queryParam.total">
      </el-pagination>
  </div>
  </div>

  <div class="fitter"></div>

  <Footer/>
</template>

<script scoped>
import Footer from "@/components/Footer.vue";
import request from "@/utils/request";

export default {
  inject: ['$request'],
  components: {
    Footer
  },
  data() {
    return {
      islook: {
        item: 0,
        istrue:false
      },

      baseUrl: request.defaults.baseURL+'/images/',
      news: [],
      queryParam: {
        total: 5,
        pageNum: 1,
        pageSize: 10,
        currentPage: 1,
      }
    }
  },
  mounted() {
      this.creatNews()
  },

  methods: {
    creatNews() {
      request.get("/moozemall/new/list", {params: this.queryParam}).then(res =>{
        console.log(res);
        this.news = res.data.data.records;
        this.queryParam.total = res.data.data.total;
        this.queryParam.currentPage = res.data.data.current
        this.queryParam.totalRows=res.data.data.pages
      }).catch(err =>{
        console.log(err);
      })
    },
    goToDetail(New) {
      this.$router.push({path: `/newDetail`})
      localStorage.setItem('New',JSON.stringify(New))
    },

    handleCurrentChange(newPage) {
      this.queryParam.pageNum = newPage;
      this.creatNews();
    },
    enterbox(id){
      this.islook.item=id
      this.islook.istrue=true
    },
    leavebox(id){
      this.islook.item=id
      this.islook.istrue=false
    }
  }
}
</script>

<style scoped>
.main-container{
  min-height: 500px;
  overflow-x: hidden;
}

.ot{
  margin: 0 calc(50% - 150px);
}

.pic{
  width: 30%;
  margin-right: 10px;
  margin-bottom: 10px;
  float: left;
}

.new{
  margin-bottom:10px;
  font-size: 20px;
  color: #171A20;
  flex-wrap: wrap;
  word-break: break-word;
  width: calc(100% - 30px);
  white-space: pre-wrap;
}
.line3{
  border-radius: 0;
  font-size: 13px;
  border-bottom: 1px solid black;
  padding-bottom: 2px;
}
.line3:hover{
  background-color: #f5f7fa;
  padding-bottom: 0px;
  color: black;
  border-bottom: 3px solid black;
}
.content{
  margin:10px 20px;
  font-size: 12px;
 color: #393C41;
  word-break: break-all;
  white-space: pre-wrap;
  max-height: 200px;
  overflow: hidden;
}
.content1{
  margin-top: 10px;
  font-size: 12px;
  color: #393C41;
  max-height: 200px;
  overflow: hidden;
  white-space: pre-wrap;
}
.time{
  color: #5C5E62;
  font-size: 12px;
}
.fitter{
  width: 100%;
  display: flex;
  padding-top: 70px;
}
.title_name{
  color: white;
  font-size: 40px;
  margin: 50px auto;
  max-width: 700px;
  width: 70%;
}
.pic1{
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 450px;
  z-index: -1;
}
.pic2{
  width: 1620px;
  height: 450px;
  position: absolute;
  left: 50%;
  margin-left: -810px;
  contain: size;

}
.box{
  border-radius: 10px;
  margin: 50px auto;
  padding:10px ;
  max-width: 700px;
  width: 70%;
  align-self: auto;
  overflow: hidden;
}
@media screen and (max-width: 768px){

  .box{
    width: 90%;
  }
  .new{
    font-size: 17px;
  }
}
</style>
