<template>
  <el-dropdown size="medium" trigger="click">
    <a href="#" class="icon-link">
      <img src="@/assets/语言.png" alt="Icon">
    </a>
    <template #dropdown>
      <el-dropdown-menu>
        <el-dropdown-item
            v-for="(locale, key) in $i18n.messages"
            :key="key"
            @click="changeLanguage(key)"
        >
          {{ locale.language }}
        </el-dropdown-item>
      </el-dropdown-menu>
    </template>
  </el-dropdown>
</template>

<script>
import request from "@/utils/request";
import i18n from "@/locale";
export default {
  data: function () {
    return {
      dropdownVisible: false,
    };
  },

  methods: {
    toggleDropdown() {
      // 切换下拉菜单的显示和隐藏
      this.dropdownVisible = !this.dropdownVisible;
    },
    changeLanguage(key) {
      localStorage.setItem('userLanguage', key);
      window.location.reload();
    }
  },
  mounted() {

  }
};
</script>

<style scoped>
.icon-link .el-dropdown {
  /* 覆盖 el-dropdown 的样式 */
  /* 例如，取消边框 */
  border: none !important;
  /* 还可以根据需要添加其他样式 */
}

</style>