<template>
  <div class="goods-list">
    <ul class="good-box">
      <li class="good-item" v-for="(product) in products" :key="product.id" @click="goToDetail(product)">
        <div class="figure-box">
          <img class="figure" :src="product.pic" />
        </div>
        <div class="good-desc">
          <h2 class="title">{{ product.name }}</h2>
          <!-- <h2 class="title">{{ product.name }} <span class="product-tag" :class="'product-tag-' + sellStatus[index].type">{{sellStatus[index].name}}</span></h2> -->
          <p class="material">{{ product.material }}</p>
          <p class="size"><strong>{{ product.sizeValue }}</strong>{{ product.sizeUnit }}</p>
        </div>
      </li>
    </ul>
  </div>
  <Footer />
</template>

<script scoped>
import Footer from "@/components/Footer";
import request from "@/utils/request";

export default {
  name: "Figures",
  components: {
    Footer,
  },
  data() {
    return {
      products: [],
      loadCount: 0,
      allLoaded: false,
      sellStatus: [{
        type: 'sale',
        name: this.$t('ProductListStatus.Sale')
      }, {
        type: 'soldout',
        name: this.$t('ProductListStatus.SoldOut')
      }, {
        type: 'preorder',
        name: this.$t('ProductListStatus.Preparing')
      }]
    };
  },
  created() {
    document.body.style.background = 'var(--bg-color-000)';
  },
  methods: {
    handleLoaded() {
      this.loadCount++;
      if (this.loadCount === this.products.length) {
        this.allLoaded = true;
      }
    },
    goToDetail(product) {
      this.$router.push({
        name: 'Detail',
        params: { bunchId: product.bunchId }
      });
    },
  },
  async mounted() {
    await request.get(`/moozemall/figure/list?languageId=${this.$i18n.locale}`).then(res => {
      this.products = res.data.data.records.map(item => {
        const regex = /^(\d+(?:\.\d+)?)\s*([a-zA-Z]+)$/;
        const [_, sizeValue, sizeUnit] = item?.size?.match(regex) || ''; // 使用正则表达式分割数值和单位
        return {
          ...item,
          sizeValue,
          sizeUnit
        };
      });
    }).catch(e => {
      console.error(e)
    })
  }
};
</script>

<style scoped>
.goods-list {
  display: flex;
  align-items: center;
  justify-content: center;
}
.good-box {
  display: flex;
  flex-wrap: wrap;
  gap: .2083rem;
  flex: 0 0 10rem;
  margin: .4167rem auto 0;
  padding: .2083rem 0 0 .2083rem;
  box-sizing: border-box;
}
.good-item {
  position: relative;
  flex: 0 1 calc(25% - .2083rem);
  background-color: var(--bg-color);
  transition: transform 200ms linear, box-shadow 150ms linear;
  cursor: pointer;
  box-sizing: border-box;
}
.good-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 0 12px rgba(147, 149, 153, 0.1);
}
.figure-box {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.figure-box::before {
  content: "";
  display: block;
  padding-top: 100%;
}
.figure-box .figure {
  position: absolute;
  width: 100%;
  z-index: 2;
}
.good-desc {
  padding: 20px 30px 30px;
}
.good-desc .title {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  font-size: 24px;
  font-weight: 700;
}
.good-desc .title .product-tag {
  padding: 3px 8px;
  margin-left: 10px;
  font-weight: 400;
  font-size: 16px;
  border-radius: 4px;
}
.good-desc .title .product-tag-sale {
  color: #9C6708;
  background-color: #DCC291;
}
.good-desc .title .product-tag-soldout {
  color: var(--font-color);
  background-color: var(--medium-gray);
}
.good-desc .title .product-tag-preorder {
  color: #1139EC;
  background-color: #CCD4F8;
}

.good-desc .material {
  padding-bottom: 40px;
  font-weight: 300;
  font-size: 14px;
}
.good-desc .size {
  font-weight: 400;
  font-size: 12px;
}
.good-desc .size strong {
  padding-right: 4px;
  font-weight: 700;
  font-size: 16px;
}
@media screen and (min-width: 769px) and (max-width: 1440px) {
  .good-item {
    flex: 0 1 calc(33.33% - .2083rem);
  }
  .good-desc .title {
    font-size: 20px;
  }
  .good-desc .title .product-tag {
    font-size: 14px;
  }
}
@media screen and (max-width: 768px) {
  .good-box {
    gap: 10px 0;
    flex: 0 0 100%;
    margin-top: 10px;
    padding-top: 40px;
  }
  .good-item {
    flex: 0 1 100%;
    display: flex;
  }
  .figure-box {
    flex: 0 0 150px;
  }
  .figure-box::after {
    width: 60%;
    height: 60px;
  }
  .good-desc {
    padding: 20px 10px;
  }
  .good-desc .title {
    font-size: 16px;
  }
  .good-desc .title .product-tag {
    padding: 3px 6px;
    font-size: 12px;
  }
  .good-desc .material {
    font-size: 12px;
  }
}
</style>
