import axios from 'axios';
import {ElMessage} from 'element-plus';
// 创建 Axios 实例
const request = axios.create({
    baseURL: process.env.VUE_APP_BASE_API, // 使用环境变量配置 baseURL
    // baseURL: '', // 使用环境变量配置 baseURL
    timeout: 60000, // 设置请求超时时间
});

// 请求拦截器
request.interceptors.request.use(
    (config) => {
        // 在请求发送前可以进行一些配置，例如添加认证信息等
        // config.headers['X-Auth-Token'] = 'your_token'; // 示例：添加认证信息
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// 响应拦截器
request.interceptors.response.use(
    (response) => {
        // if the custom status is not 200, it is judged as an error.
        if (response.status !== 200) {
            ElMessage({
                message: response.data.message || 'Error',
                type: 'error',
                duration: 5 * 1000
            })
            // return Promise.reject(new Error(response.data.message ||'Error'))
            return response
        } else {
            return response
        }
    },
    (error) => {
        // 处理响应错误
        // ...
        return Promise.reject(error);
    }
);

export default request;