import {createApp} from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import i18n from './locale/index'
import request from "@/utils/request";
import * as Icons from '@element-plus/icons-vue'
import Badge from 'vue-devui/badge'
import 'vue-devui/badge/style.css';
import '@/assets/css/main.css'
import './utils/flexible'

const app = createApp(App)

app.provide('$request', request)

app.use(Badge)

app.use(store)
app.use(router)
app.use(i18n)
app.use(ElementPlus)
for (const [key, component] of Object.entries(Icons)) {
    app.component(key, component)
}
app.mount("#app")