<template>
  <div class="main-container">
    <div class="fitter"></div>
    <el-table
        ref="multipleTableRef"
        :data="tableData"
        class="table"
        stripe
        style="width: 90%;margin: auto"
    >

      <el-table-column label="" width="120">
        <template #default="scope"><el-image style="width: 120px; height: 120px"  class="pic" :src="scope.row.pic" fit="contain"/></template>
      </el-table-column>

      <el-table-column property="totalName" label="" width=auto></el-table-column>

      <el-table-column label="" width="150">
        <template #default="scope">{{ scope.row.priceStr }}</template>
      </el-table-column>
    </el-table>

    <div style="position: absolute; right: 12%;">{{ $t('PayPage_Total') }}: {{ total }}</div>
    <div style="width: 50%; margin: auto;">
      <el-link class="line3"  :underline="false" @click="showaddress" >快速填写已保存的地址</el-link>
    </div>

    <el-form :model="form"  ref="form">
      <div class="form">
      <el-form-item label="" prop="username" :rules="{
        required: true,
        message: '',
        trigger: 'blur',
      }" class="form-item">
        {{ $t('Account_Name') }}
        <el-input id="name" v-model="form.username"/>
      </el-form-item>
        <el-form-item label="" prop="phone" :rules="{
        required: true,
        message: '',
        trigger: 'blur',
      }" class="form-item">
          {{ $t('Account_Phone') }}
          <el-input id="phone" v-model="form.phone"/>
        </el-form-item>


        <div class="form-item2">
        </div>
        <div class="form-item2">
        </div>
        <el-form-item label="" prop="address" :rules="{
        required: true,
        message: '',
        trigger: 'blur',
      }" class="form-item">
        {{ $t('Account_Address_Line1') }}
        <el-input id="address" v-model="form.address"/>
      </el-form-item>

      <el-form-item label="" prop="address2" :rules="{
        required: true,
        message: '',
        trigger: 'blur',
      }" class="form-item">
        {{ $t('Account_Address_Line2') }}
        <el-input id="address2" v-model="form.address2"/>
      </el-form-item>

      <el-form-item label="" prop="postcode" :rules="{
        required: true,
        message: '',
        trigger: 'blur',
      }" class="form-item">
        {{ $t('Account_Postcode') }}
        <el-input id="postcode" v-model="form.postcode"/>
      </el-form-item>

      <div class="form-item">
        {{ $t('Account_Country') }}
        <br>
        <el-select v-model="form.city" style="width: 300px;margin-top: 10px" placeholder="USD">
          <el-option
              v-for="(item,index) in options"
              :key="item.id"
              :label="item.counName"
              :value="item.counName"
              @click.native="changeregion(index)"
          />
        </el-select>
      </div>
      </div>
      <el-row :gutter="20" class="row">
        <el-col :span="6"></el-col>
        <el-col :span="12">
          <el-button class="back" @click="backtocart">{{ $t('Common_Back') }}</el-button>
        </el-col>
        <el-col :span="6">
          <el-button type="submit" class="btn" @click="nextTo('form')">{{ $t('Common_Continue') }}</el-button>
        </el-col>
      </el-row>
    </el-form>
    <div>

    </div>

    <div class="fitter"></div>
  </div>
  <Footer/>
  <el-dialog v-model="isshow"  style="border-radius: 10px ;width: 90%;min-height: 600px"   align-center @close="istrue=false" >
    <div class="box">
      <el-card v-for="add in address" class="card"  shadow="hover">
        <div style="float: left">{{ $t('Account_Name') }}:</div>
          <div style="float: right">{{ add.username }}</div>
        <br>
        <div style="float: left">{{ $t('Account_Phone') }}:</div>
        <div style="float: right">{{ add.phone }}</div>
        <br>
        <div style="float: left">{{ $t('Account_Address_Line1') }}:</div>
        <div style="float: right">{{ add.address }}</div>
        <br>
        <div style="float: left">{{ $t('Account_Address_Line2') }}:</div>
        <div style="float: right">{{ add.address2 }}</div>
        <br>
        <div style="float: left">{{ $t('Account_Postcode') }}:</div>
        <div style="float: right">{{ add.postcode }}</div>
        <br>
        <div style="float: left">{{ $t('Account_Country') }}:</div>
        <div style="float: right">{{ add.city }}</div>
        <br>
        <el-button   style="margin: 10px" @click="selectaddress(add)">{{ $t('Common_Continue') }}</el-button>
      </el-card>
</div>



  </el-dialog>
</template>


<script scoped>
import Footer from "@/components/Footer.vue";
import request from "@/utils/request";
import PayPal from 'vue-paypal-checkout'
import {getItemWithExpiry} from "@/utils/storage";

export default {
  name: "purchase",
  components: {
    Footer,
    PayPal
  },
  data() {
    return {
      total: '',
      isshow:false,
      address:[],
      userId:'',
      queryParams: {
        total: 0,
        code: "USD",
        baseUrl:window.location.origin,
      },
      rates: {},
      queryParam: {
        country: "US"
      },
      queryParam2: {},
      tableData: [],
      id: 1,
      form1: {
        counName: "US",
        currCode: 'USD'
      },
      form: {},
      order: {
        userId: 1,
        email: '1111',
        status: 1,
      },
      orders: [],
      options: []
    }
  },
  mounted() {
    this.userId = getItemWithExpiry('userId');
    if (this.userId == null)
      this.$router.push('/login')
    this.getuser(this.userId)
    this.queryParam2 = JSON.parse(localStorage.getItem('car'));
    this.getpurchase()
    this.getcountry()
    this.getaddress()
  },

  methods: {
    selectaddress(val){
      this.form=val
      this.isshow=false
      this.getcountrydetail(val.city)
    },
    getaddress(){
      request.get("moozemall/address/list?userId="+this.userId).then(res => {
        console.log(res)
        this.address = res.data.data
      })
    },
    getuser(val) {
      request.get("moozemall/user/" + val).then(res => {
        this.order.userId = res.data.data.id
        this.order.email = res.data.data.email
      })
    },
    showaddress(){
      this.isshow=true
    },
    getcountry() {
      request.get("moozemall/country/list/"+this.$i18n.locale).then(res => {
        console.log(res.data.data)
        this.options = res.data.data
      })
    },
    getpurchase() {
      request.post("moozemall/car/bugPage", this.queryParam2, {
            params: this.queryParam
          }
      ).then(res => {
        console.log(res.data.data)
          this.tableData= res.data.data
          this.queryParams.total = res.data.sumNum
        this.total=res.data.sumString
      })
    },

    backtocart() {
      this.$router.push('/bag')
    },
    nextTo(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          for (let i = 0; i < this.tableData.length; i++) {
            var item = {}
            item.userId = this.order.userId
            item.email = this.order.email
            item.status = this.order.status
            item.address = this.form.address
            item.phone = this.form.phone
            item.city = this.form1.counName
            item.username = this.form.username
            item.address2 = this.form.address2
            item.postcode = this.form.postcode
            item.orderName = this.queryParam2[i].totalName
            item.price = this.queryParam2[i].price
            item.priceCode = this.queryParam2[i].priceStr
            this.orders.push(item)
          }
          this.purchase()
        } else {
          console.log('error submit!!');
          return false;
        }
      });

      console.log(this.form)
    },
    purchase() {
      request.post("/pay", this.orders, {params: this.queryParams}).then(res => {
        this.orders = []
        if (res.data.data != null)
          location.href = res.data.data
        else this.$message.error("支付失败")
      })
    },
    changeregion(index) {
        this.form1 = this.options[index]
      this.queryParams.code = this.form1.currCode
        this.queryParam.country = this.form1.isoE
        this.getpurchase()
    },
    getcountrydetail(val) {
      request.get('moozemall/country/list?counName='+val).then(res => {
        this.form1=res.data.data[0]
        this.queryParams.code = this.form1.currCode
        this.queryParam.country = this.form1.isoE
        this.getpurchase()
      })
    },
  }
}
</script>

<style scoped>
.main-container{
  min-height: calc(100vh - 40px);
}


.fitter {
  width: 100%;
  display: flex;
  padding-top: 70px;
}
.card{
  position: relative;
  width: 90%;
  max-width: 300px;
  margin:10px;
  height: 200px;
}
.pic {
  width: 60px;
  height: 75px;
}

.back {
  margin: 50px 25%;
}

.btn {
  margin: 50px 25%;
}
.box{
  position: relative;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;

}
.row {
  width: 80%;
}

.form {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
  height: 350px;
  margin: 20px auto;
  flex-direction: column;
}

.form-item {
  width: 30%;
  margin: 5px 10%;
  height: 70px;
}
.form-item2{
  width: 30%;
  margin: 5px 10%;
  height: 70px;
}
.line3{
  border-radius: 0;
  border-bottom: 1px solid black;
  padding-bottom: 2px;
}
.line3:hover{
  background-color: #f5f7fa;
  padding-bottom: 0px;
  color: black;
  border-bottom: 3px solid black;
}
@media screen and (max-width: 1000px){
  .form{
    height: auto;
    flex-direction: row;
  }
  .form-item {
    width: 400px;
    margin: 5px 10%;
    height: 70px;
  }
  .form-item2{
    display: none;
  }

}
</style>