<template>
  <div class="registerBody">
    <div class="registerData">
      <div class="registerText">
        <h>{{ $t('LoginPage_Create_Title') }}</h>
      </div>
      <div class="formData">
        <el-form ref="user" :model="user" :rules="addUserRules">
          <el-form-item prop="name">
            <el-input class="login-input" v-model="user.name" clearable
              :placeholder="$t('LoginPage_Name_Placeholder')"></el-input>
          </el-form-item>
          <el-form-item prop="email">
            <el-input class="login-input" v-model="user.email" clearable
              :placeholder="$t('LoginPage_Email_Placeholder')"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button class="loginBtn" @click="submitForm('user')">{{ $t('Common_Continue') }}</el-button>
          </el-form-item>
        </el-form>
        <div class="register_user">
          {{ $t('LoginPage_IsRegistered') }}<a href="javascript: void(0);" @click="goToLogin">{{
            $t('LoginPage_Goto_Login') }}</a>
        </div>
      </div>
    </div>
  </div>
  <Footer />
</template>

<script>
import { useRouter } from 'vue-router'
import Footer from "@/components/Footer.vue";
import request from "@/utils/request";
import { ElMessage } from "element-plus";

export default {
  components: {
    Footer
  },
  data() {
    const checkEmail = (rule, value, callback) => {
      const regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (regEmail.test(value)) {
        return callback();
      }
      callback(new Error(""));
    };
    return {
      user: {
        name: '',
        email: ''
      }, addUserRules: {
        email: [
          { required: true, message: `${this.$t('LoginPage_Email_Required')}`, trigger: "blur" },
          { validator: checkEmail, message: `${this.$t('LoginPage_Email_Error')}`, trigger: "blur" },
        ],
      },
      ishave: false,

      queryParam: { code: window.location.origin + "/setPassword" },

    }
  },
  methods: {
    submitForm(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          this.ishave = false
          request.post('/moozemall/user/register', this.user, { params: this.queryParam }).then(res => {
            console.log(res.data.data)
            if (res.data.data == "300") {
              this.$message.error(`${this.$t('LoginPage_Exist')}`)
              this.ishave = true
            }
            else
              this.$message.success(`${this.$t('LoginPage_Send_Email')}`)
            this.ishave = false
          }
          )
        }
      })
    },
    goToLogin() {
      this.$router.push('/login')
    }
  }
}
</script>

<style scoped>
.registerBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100vh - 1.0417rem);
  min-height: 320px;
  box-sizing: border-box;
}

.registerData {
  width: 380px;
  padding: 40px;
  padding: 40px;
  margin-top: 100px;
  box-sizing: border-box;
}

.registerText {
  margin-bottom: .3125rem;
  font-size: 24px;
  font-weight: 400;
  text-align: center;
}

.login-input,
.loginBtn {
  width: 100%;
  height: 40px;
}
.loginBtn {
  font-weight: 400;
  color: var(--bg-color);
  background-color: #F3F5F9;
}

.register_user {
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  color: var(--light-gray);
}
.register_user a {
  margin-left: 4px;
  text-decoration: none;
}

@media screen and (max-width: 768px) {
  .registerText {
    margin-bottom: 30px;
  }
  .registerData {
    width: 100%;
    height: auto;
    padding: 0 40px;
  }
}
</style>
<style>
.registerData .el-form-item {
  margin-bottom: 40px;
}
</style>