<template>
  <swiper class="swiperBox" :modules="swiperModules" :slides-per-view="slidesPerView" :pagination="{ clickable: true }">
    <swiper-slide v-for="section in homeSections" :key="section.id">
      <Section :idSection="section.idSection" :title="section.title" :paragraph="section.paragraph"
        :bgImage="section.bgImage" :showDiv="showDiv" class="item" />
    </swiper-slide>
    <div class="swiper-pagination"></div>
  </swiper>
</template>

<script>
import Section from "@/components/Section";
import FooterWhite from "@/components/FooterWhite.vue";
import request from "@/utils/request";

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Swiper, SwiperSlide } from 'swiper/vue';

// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';

export default {
  name: "ViewHome",
  data() {
    return {
      showDiv: true, // 初始状态为显示
      isScrolling: false,
      showFooter: false,
      slidesPerView: 1,
    };
  },
  components: {
    FooterWhite,
    Section,
    Swiper,
    SwiperSlide,
  },
  computed: {
    homeSections() {
      return this.$store.getters["homeSections"];
    },
    swiperModules() {
      return [Navigation, Pagination, Scrollbar, A11y];
    },
  },
  created() {
    document.body.style.background = 'var(--bg-color-000)';
  },
  mounted() {
    // 清空之前的数据
    const langId = this.$i18n.locale;
    this.$store.commit("clearSections");
    request.get(`/moozemall/selected/list?languageId=${langId}`).then(res => {
      let selected = res.data.data;
      console.log(111)
      if (!Array.isArray(selected) || selected.length === 0) {
        return
      }
      selected.sort((a, b) => a.sort - b.sort);
      this.$store.dispatch("changeSection", selected[0].figureId);
      for (let i = 0; i < selected.length; i++) {
        // 首页轮播图替换，使用项目中的图片
        selected[i].url = `home_0${i + 1}.jpg`
        this.$store.commit("addSection", {
          id: selected[i].figureId,
          idSection: selected[i].figureId,
          title: selected[i].name,
          paragraph: selected[i].intro,
          bgImage: selected[i].url,
        });
      }
    });
  },
  methods: {
    handleScroll(event) {
      const { scrollTop, clientHeight, scrollHeight } = event.target;
      this.showFooter = false;

      if (scrollTop + clientHeight + 1 >= scrollHeight) {
        this.showFooter = true;
      }

      this.isScrolling = true;
      this.showDiv = false; // 滚动时隐藏

      // 清除之前的定时器
      clearInterval(this.intervalId);

      // 设置一个新的定时器，检测滚动是否停止
      this.intervalId = setInterval(() => {
        this.isScrolling = false;
        this.showDiv = true; // 停止滚动时显示
      }, 200); // 200毫秒是一个常见的时间阈值，你可以根据需要调整
    }
  },
};
</script>

