<template>
  <div class="loginbody">
    <div class="logindata">
      <div class="login-title">
        <h>{{ $t('LoginPage_Login') }}</h>
      </div>

      <div class="formdata">
        <el-form ref="form" :model="form" :rules="rules">
          <el-form-item prop="email">
            <el-input class="login-input" v-model="form.email" clearable :placeholder="$t('LoginPage_Email_Placeholder')"></el-input>
          </el-form-item>

          <el-form-item prop="password">
            <el-input class="login-input" v-model="form.password" clearable :placeholder="$t('LoginPage_Password_Placeholder')"
              show-password></el-input>
          </el-form-item>

          <a @click="resetPassword" v-show="loginAttempts >= 3" href="javascript: void(0);" class="resetPassword">{{ $t('LoginPage_ResetPassword') }}</a>

          <el-form-item>
            <el-button @click="login" class="loginBtn">{{ $t('LoginPage_Login') }}
            </el-button>
          </el-form-item>
        </el-form>

        <div class="register_user">
          {{ $t('LoginPage_UnRegistered') }}<a href="javascript: void(0);" @click="register">{{ $t('LoginPage_Create') }}</a>
        </div>
      </div>
    </div>
  </div>
  <Footer />
</template>

<script>

import Footer from "@/components/Footer.vue";
import request from "@/utils/request";
import { setItemWithExpiry, getItemWithExpiry } from '@/utils/storage.js'
import { ElMessage } from "element-plus";

export default {
  name: "login",
  components: { Footer },
  data() {
    return {
      form: {
        password: "",
        email: "",
      },
      user: {},
      ishave: false,
      loginAttempts: 0,
      checked: false,
      queryParam: { code: window.location.origin + "/setPassword" },
      rules: {
        email: [
          { required: true, trigger: "blur", message: `${this.$t('LoginPage_Email_Required')}` },
          { type: 'email', message: `${this.$t('LoginPage_Email_Error')}`, trigger: ['blur', 'change'] }
        ],
        password: [
          { required: true, trigger: "blur", message: `${this.$t('LoginPage_Password_Required')}` },
          // {
          //   validator: (rule, value, callback) => {
          //     const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{6,20}$/
          //     if (!pattern.test(value)) {
          //       callback(new Error('密码必须包含英文大小写+数字，且6-20位'))
          //     } else {
          //       callback()
          //     }
          //   },
          //   trigger: 'blur'
          // }
        ],
      },
    };
  },
  mounted() {
    if (localStorage.getItem("news")) {
      this.form = JSON.parse(localStorage.getItem("news"))
      this.checked = true
    }
  },
  methods: {
    login() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          request.post("/user/login", this.form).then((res) => {
            if (res.data.status === 200) {
              this.$message({
                message: `${this.$t('LoginPage_Login_Success')}`,
                type: "success",
              });
              setItemWithExpiry('token', res.data.message, 12 * 1000 * 60 * 60) // 存储12小时
              setItemWithExpiry('userId', res.data.data, 12 * 1000 * 60 * 60) // 存储12小时
              window.location.reload()
              this.$router.push("/account");
            } else if (res.data.data === 300) {
              this.ishave = true
              this.$message({
                message: `${this.$t('LoginPage_Email_Unfind')}`,
                type: "error",
              });
            } else {
              this.ishave = false
              this.user = this.form
              this.$message({
                message: `${this.$t('LoginPage_Password_Error_Tip')}`,
                type: "error",
              });
              this.loginAttempts++
            }
          });
        } else {
          return;
        }
      });
    },
    resetPassword() {
      this.$router.push("/resetPassword")
    },
    register() {
      this.$router.push("/register")
    },
  },
};
</script>

<style scoped>
.loginbody {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100vh - 1.0417rem);
  min-height: 320px;
  box-sizing: border-box;
}
.logindata {
  width: 380px;
  padding: 40px;
  margin-top: 100px;
  box-sizing: border-box;
}
.login-title {
  margin-bottom: .3125rem;
  font-size: 24px;
  font-weight: 400;
  text-align: center;
}
.login-input,
.loginBtn {
  width: 100%;
  height: 40px;
}
.loginBtn {
  font-weight: 400;
  color: var(--bg-color);
  background-color: #F3F5F9;
}
.loginBtn:hover {
  color: var(--bg-color);
  background-color: var(--font-color);
}

.register_user {
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  color: var(--light-gray);
}
.register_user a {
  margin-left: 4px;
  text-decoration: none;
}

.el-link.is-underline::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  height: 0;
  bottom: 0;
  border-bottom: 1px solid grey;
}

@media screen and (max-width: 768px) {
  .login-title {
    margin-bottom: 30px;
  }
  .logindata {
    width: 100%;
    height: auto;
    padding: 0 40px;
  }
}

</style>
<style>
.login-input .el-input__wrapper {
  font-weight: 400;
  background-color: rgba(255, 255, 255, 0.1);
  box-shadow: none;
  transition: box-shadow 200ms linear;
  box-sizing: border-box;
}
.el-form-item.is-error .el-input__wrapper, .el-form-item.is-error .el-input__wrapper.is-focus, .el-form-item.is-error .el-input__wrapper:focus, .el-form-item.is-error .el-input__wrapper:hover, .el-form-item.is-error .el-select__wrapper, .el-form-item.is-error .el-select__wrapper.is-focus, .el-form-item.is-error .el-select__wrapper:focus, .el-form-item.is-error .el-select__wrapper:hover, .el-form-item.is-error .el-textarea__inner, .el-form-item.is-error .el-textarea__inner.is-focus, .el-form-item.is-error .el-textarea__inner:focus, .el-form-item.is-error .el-textarea__inner:hover {
  box-shadow: none !important;
}
.login-input .el-input__wrapper:has(.el-input__inner:focus) {
  color: var(--font-color);
  box-shadow: 0 0 0 1px var(--font-color) inset !important;
}
.login-input .el-input__wrapper .el-input__inner {
  color: #939599;
}
.login-input .el-input__wrapper .el-input__inner:focus {
  color: var(--font-color);
}
.login-input.el-input .el-input__icon.el-input__clear svg {
  display: none;
}
.login-input.el-input .el-input__icon.el-input__clear {
  width: 16px;
  height: 16px;
  background: url(@/assets/icon/icon_clear.png) no-repeat 0 0;
  background-size: cover;
}
.login-input + .el-form-item__error {
  margin-top: 10px;
  font-weight: 400;
}
.login-input + .el-form-item__error::before {
  content: "";
  display: inline-block;
  width: 14px;
  height: 14px;
  overflow: hidden;
  margin-right: 4px;
  vertical-align: -3px;
  background-image: url("data:image/svg+xml;utf-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%201024%201024%22%3E%3Cpath%20fill%3D%22%23f56c6c%22%20d%3D%22m466.752%20512-90.496-90.496a32%2032%200%200%201%2045.248-45.248L512%20466.752l90.496-90.496a32%2032%200%201%201%2045.248%2045.248L557.248%20512l90.496%2090.496a32%2032%200%201%201-45.248%2045.248L512%20557.248l-90.496%2090.496a32%2032%200%200%201-45.248-45.248z%22%3E%3C%2Fpath%3E%3Cpath%20fill%3D%22%23f56c6c%22%20d%3D%22M512%20896a384%20384%200%201%200%200-768%20384%20384%200%200%200%200%20768m0%2064a448%20448%200%201%201%200-896%20448%20448%200%200%201%200%20896%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  background-position: center;
}
.resetPassword {
  float: right;
  margin-top: -30px;
  font-size: 12px;
  font-weight: 400;
  text-decoration: none;
}
.logindata .el-form-item {
  margin-bottom: 40px;
}
</style>