<template>
  <div class="main-container">
    <div class="fitter"></div>
    <div class="newName">
      {{ $t('BottomBar_Contact') }}
    </div>
    <div class="abs">
      {{ New.title }}
    </div>
    <div class="abs2">{{ New.text }}</div>
    <div class="fitter"></div>
  </div>
  <Footer />
</template>

<script scoped>
import Footer from "@/components/Footer.vue";
import request from "@/utils/request";

export default {
  name: "contact_us",
  components: { Footer },
  data() {
    return {
      New: {
        // id: 1,
        // time: '2024-01-03',
        // title: '我的地址',
        // abs: 'ssssssssssssssssssssssssssssssssssssssssssssssssss',
        // text: '销售\n' +
        //   '\n' +
        //   '咨询或预约试驾：400-910-0707\n' +
        //   '\n' +
        //   '前往找到我们页面，查找您附近的特斯拉体验店。\n' +
        //   '\n' +
        //   '客户服务与道路救援\n' +
        //   '\n' +
        //   '24 小时服务热线：400-910-0707\n' +
        //   '\n' +
        //   '联系邮箱：400@tesla.com\n' +
        //   '\n' +
        //   '工作机会\n' +
        //   '\n' +
        //   '访问我们的工作机会页面，浏览当前开放的职位信息。\n' +
        //   '\n' +
        //   '紧急响应\n' +
        //   '\n' +
        //   '访问我们的紧急响应指南页面，下载消防和急救人员参考文档。\n' +
        //   '\n' +
        //   '新闻中心\n' +
        //   '\n' +
        //   '美洲\n' +
        //   '\n' +
        //   'press@tesla.com\n' +
        //   '\n' +
        //   '欧洲和中东\n' +
        //   '\n' +
        //   'eupress@tesla.com\n' +
        //   '\n' +
        //   '亚太\n' +
        //   '\n' +
        //   'apacpress@tesla.com\n' +
        //   '\n' +
        //   '中国大陆\n' +
        //   '\n' +
        //   'china-press@tesla.com',
        // pic: 'https://g-search3.alicdn.com/img/bao/uploaded/i4/i2/2214325162924/O1CN015t9noa1XTF0K9qBAP_!!2214325162924.png_580x580q90.jpg_.webp'
      }
    }
  },
  mounted() {
    this.gettext()
  },
  methods: {
    gettext() {
      request.get('moozemall/text/2/' + this.$i18n.locale).then(res => {
        if (res.data.data != null)
          // this.New = res.data.data
        this.New = {}
      })
    }
  }

}
</script>

<style scoped>
.main-container {
  min-height: calc(100vh - 40px);
  overflow-x: hidden;
}

.fitter {
  width: 100%;
  display: flex;
  padding-top: 70px;
}

Footer {
  position: absolute;
  bottom: 0;
}

.newName {
  max-width: 900px;
  font-size: 30px;
  margin: 70px auto;
  width: 90%;
}

.abs {
  font-size: 18px;
  max-width: 900px;
  width: 90%;
  margin: 10px auto;
  flex-wrap: wrap;
  word-break: break-word;
  white-space: pre-wrap;
}

.abs2 {
  font-size: 14px;
  max-width: 900px;
  width: 90%;
  margin: auto;
  flex-wrap: wrap;
  word-break: break-word;
  white-space: pre-wrap;
}
</style>