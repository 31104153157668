<template>
  <div><error>error</error></div>
</template>

<script scoped>
export default {
  name: "error",
  data(){
    return{
      queryParam:{}
    }
  },
  mounted() {
      this.$router.push('/')
  },
  methods:{
  }
}
</script>

<style scoped>

</style>