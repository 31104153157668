<template>
  <div :class="customClass" class="footer-wrap" v-show="isReady">
    <!-- <a class="footer-link" href="/privacy">{{ $t('BottomBar_Privacy') }}</a>
    <a class="footer-link" href="news">{{ $t('BottomBar_News') }}</a> -->
    <a class="footer-link" href="/contact">{{ $t('BottomBar_Contact') }}</a>

    <SocialLink customClass="footer-link" />
  </div>

</template>

<script>
import SocialLink from "@/components/SocialLink";
import request from "@/utils/request";

export default {
  name: "Footer",
  components: {
    SocialLink,
  },
  props: {
    customClass: {
      type: String,
      default: 'footer'
    }
  },
  data() {
    return {
      Twitter: {},
      Facebook: {},
      isReady: false
    }
  },
  mounted() {
    this.isReady = false;
    request.get('/moozemall/account/list').then(res => {
      this.Twitter = res.data.data.records[0];
      this.Facebook = res.data.data.records[1];
      this.isReady = true;
    });
  }
}
</script>

<style>
.footer-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  height: 40px;
  padding: 5px;
  margin-top: auto;
  font-size: 14px;
}
.icon {
  width: 16px;
  height: 16px;
}

.footer-link {
  font-size: 14px;
  font-weight: 400;
  text-decoration: none;
  color: #666;
  opacity: 1;
}
.footer-link:hover {
  color: var(--font-color);
}

@media screen and (max-width: 768px) {
  .footer-link {
    padding: 6px 10px;
  }
}
</style>

<script setup>

</script>
