import {createApp} from "vue";
import {createI18n} from "vue-i18n";
import {switchLanguage} from "@/utils/fontSwitcher";

// 加载所有语言环境并记住上下文
function loadMessages() {
    const context = require.context("./modules", true, /[a-z0-9-_]+.json$/i);

    const messages = context
        .keys()
        .map((key) => ({key, locale: key.match(/[a-z0-9-_]+/i)[0]}))
        .reduce(
            (messages, {key, locale}) => ({
                ...messages,
                [locale]: context(key),
            }),
            {}
        );

    return {context, messages};
}

const {context, messages} = loadMessages();

// VueI18n 实例
const i18n = createI18n({
    // locale: localStorage.getItem('userLanguage') || navigator.language, // 优先使用用户选择的语言
    locale: '2',
    messages,
});

const app = createApp({
    // ...
});

// 使用插件
app.use(i18n);

// 设置全局属性
app.config.globalProperties.$i18n = i18n;

// 运行程序
app.mount("#app");

// 当用户切换语言时，保存用户选择的语言
i18n.global.locale = localStorage.getItem('userLanguage') || "2";
localStorage.setItem('userLanguage', i18n.global.locale);
switchLanguage(i18n.global.locale);

// 热更新支持
if (module.hot) {
    module.hot.accept(context.id, () => {
        const {messages: newMessages} = loadMessages();

        Object.keys(newMessages)
            .filter((locale) => messages[locale] !== newMessages[locale])
            .forEach((locale) => {
                messages[locale] = newMessages[locale];
                i18n.global.setLocaleMessage(locale, messages[locale]);
            });
    });
}

export default i18n;