<template>
  <div :class="customClass" v-show="isReady">
    <a class="footer-link-white" href="/privacy">{{ $t('BottomBar_Privacy') }}</a>
    <a class="footer-link-white" href="news">{{ $t('BottomBar_News') }}</a>
    <a class="footer-link-white" href="/contact">{{ $t('BottomBar_Contact') }}</a>

    <a v-show="Twitter.status === 1" :href="Twitter.address" target="_blank" class="footer-link-white"><img class="icon"
                                                                                                      src="@/assets/推特-白.png"
                                                                                                      alt="Icon"></a>
    <a v-show="Facebook.status === 1" :href="Facebook.address" target="_blank" class="footer-link-white"><img class="icon"
                                                                                                        src="@/assets/facebook-白.png"
                                                                                                        alt="Icon"></a>
  </div>

</template>

<script>
import request from "@/utils/request";

export default {
  name: "Footer",
  props: {
    customClass: {
      type: String,
      default: 'footer'
    }
  },
  data() {
    return {
      Twitter: {},
      Facebook: {},
      isReady: false
    }
  },
  mounted() {
    this.isReady = false;
    request.get('/moozemall/account/list').then(res => {
      this.Twitter = res.data.data.records[0];
      this.Facebook = res.data.data.records[1];
      this.isReady = true;
    });
  }
}
</script>

<style scoped>
.icon {
  width: 20px;
  height: 20px;
}

.footer {
  display: flex;
  justify-content: center;
  z-index: 200;
  padding: 5px;
  font-size: 14px;
  height: 30px;
  color: white;
  margin-top: auto;
  background-color: transparent;
}

.footer-grey {
  display: flex;
  justify-content: center;
  z-index: 200;
  padding: 5px;
  font-size: 14px;
  height: 30px;
  color: white;
  margin-top: auto;
  background-color: #f5f5f5;
}

.footer-absolute {
  position: fixed;
  display: flex;
  justify-content: center;
  z-index: 200;
  padding: 5px;
  font-size: 14px;
  bottom: 0;
  width: 100%;
  height: 30px;
  color: white;
  background-color: transparent;
}

.footer-link {
  color: black;
  text-decoration: none;
  padding: 6px 25px;
  opacity: 1;
  font-size: 12px;
}

.footer-link-white {
  color: white;
  text-decoration: none;
  padding: 6px 25px;
  opacity: 1;
  font-size: 12px;
}

.footer-link-white:hover {
  background: #6b798e;
  border-radius: 20px;
}

@media screen and (max-width: 768px) {
  .footer-link {
    padding: 6px 10px;
  }

  .footer-link-white {
    padding: 6px 10px;
  }
}

</style>

<script setup>

</script>